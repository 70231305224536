import { WppListItem, WppSelect } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import { useOs } from '@wpp-open/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useFetchTenantGroupsApi } from 'api/tenant/queries/useFetchTenantGroupsApi'
import { useField } from 'hooks/form/useField'
import { useToast } from 'hooks/useToast'
import { TenantGroup } from 'types/tenants/tenant'

interface Props {
  initialValue?: MayBeNull<TenantGroup>
  'data-testid'?: string
}

export const SelectOsGroupForm = ({ 'data-testid': dataTestId, initialValue }: Props) => {
  const { t } = useTranslation()
  const { showToast } = useToast()
  const {
    osContext: { tenant },
  } = useOs()
  // use custom label to display label w/o loaded option
  const [selectedLabel, setSelectedLabel] = useState<string>(initialValue?.name || '')

  const {
    field: { value, onChange, onBlur },
  } = useField({
    name: 'groupId',
  })

  const [search, setSearch] = useState('')
  const {
    data: groups,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isError,
  } = useFetchTenantGroupsApi({
    params: { tenantId: tenant.id, search: search.trim().length >= 2 ? search.trim() : undefined },
  })

  useEffect(() => {
    if (!isError) return

    showToast({
      type: 'error',
      message: t('project.phase.settings_modal.group_load_failed'),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError])

  return (
    <WppSelect
      value={value}
      displayValue={selectedLabel}
      name="groupId"
      required
      labelConfig={{ text: t('project.phase.settings_modal.group') }}
      placeholder={t('project.phase.settings_modal.group_placeholder')}
      dropdownPosition="fixed"
      dropdownConfig={{
        onHidden: onBlur,
      }}
      data-testid={dataTestId}
      infinite
      loading={isLoading}
      infiniteLastPage={!hasNextPage}
      loadMore={fetchNextPage as () => Promise<any>}
      withSearch
      onWppChange={e => {
        onChange(e.detail.value)
      }}
      onWppSearchValueChange={(e: CustomEvent) => setSearch(e.detail)}
    >
      {groups.map(group => (
        <WppListItem
          key={group.id}
          value={group.id}
          onWppChangeListItem={e => {
            // `onWppChange` handler in `WppSelect` will not handle click on the selected item
            const isDeselecting = value === e.detail.value
            if (isDeselecting) {
              onChange('')
              setSelectedLabel('')
              return
            }

            setSelectedLabel(group.name)
          }}
        >
          <span slot="label">{group.name}</span>
        </WppListItem>
      ))}
    </WppSelect>
  )
}
