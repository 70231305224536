import { WppButton, WppDivider, WppLabel, WppTypography } from '@platform-ui-kit/components-library-react'
import { AxiosError } from 'axios'
import { useEffect, useMemo } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as zod from 'zod'

import { usePatchTaskApi } from 'api/canvas/mutation/usePatchTaskApi'
import { Flex } from 'components/common/flex/Flex'
import { SideModalContentSkeleton } from 'components/common/sideModalSkeleton/SideModalContentSkeleton'
import { tableActions } from 'components/common/table'
import { FormInput } from 'components/form/formInput/FormInput'
import { FormTextareaInput } from 'components/form/formTextareaInput/FormTextareaInput'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { TableKey } from 'constants/table'
import { useForm } from 'hooks/form/useForm'
import { useToast } from 'hooks/useToast'
import { ErrorState } from 'pages/components/templatePreviewModal/utils'
import { SelectOsGroupForm } from 'pages/project/components/canvas/components/selectOsGroup/SelectOsGroupForm'
import { taskTargetTypeMap } from 'pages/project/components/tasks/components/taskDetailsModal/TaskDetailsModal'
import { TaskLocation } from 'pages/project/components/tasks/components/taskDetailsModal/TaskLocation'
import { isHTML, parseHTMLString } from 'pages/project/components/tasks/components/utils'
import styles from 'pages/project/components/tasks/Tasks.module.scss'
import { queryClient } from 'providers/osQueryClient/utils'
import { MayBeNull } from 'types/common/utils'
import { Task } from 'types/projects/tasks'
import { NiceModalWrappedProps } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  task: MayBeNull<Task>
  isLoading: boolean
  canEdit: boolean
  isError: boolean
  error: AxiosError<unknown, any> | null
  isWrikeEnabled?: MayBeNull<boolean>
  showProjectName?: boolean
}

const taskValidationSchema = zod
  .object({
    name: zod
      .string()
      .min(1, 'Task name should contain at least 1 character')
      .max(256, 'Task name exceeded the maximum number of characters'),
    description: zod.string().max(1000, 'Description should have 1000 characters at most').optional(),
  })
  .passthrough()

interface BaseFormValues {
  name?: string
  description?: string
  groupId?: string | null
}

const defaultValues: BaseFormValues = {
  description: '',
  name: '',
  groupId: null,
}

export const TemplateTaskDetails = ({
  task,
  isLoading,
  canEdit,
  onClose,
  onCloseComplete,
  isOpen,
  id,
  isError,
  error,
}: Props) => {
  const { showToast } = useToast()
  const { t } = useTranslation()

  const { mutateAsync: handleUpdateTask } = usePatchTaskApi()

  const form = useForm({ defaultValues: { ...defaultValues }, validationSchema: taskValidationSchema })

  const {
    handleSubmit,
    formState: { isSubmitting },
    setValues,
  } = form

  useEffect(() => {
    if (task && canEdit) {
      const { name, description } = task

      // @TODO: Wrike can send empty tag, as empty description
      const fixedDescription = description === '<br />' ? '' : description || ''

      setValues({
        name,
        description: fixedDescription,
      })
    }
  }, [canEdit, setValues, task])

  const onSubmit = handleSubmit(async ({ description, name, groupId }) => {
    try {
      if (!task) return
      await handleUpdateTask({
        id: task.id,
        description: description?.trim() || null,
        name: name?.trim() || null,
        groupId: groupId || null,
      })

      queryClient.invalidateQueries([ApiQueryKeys.PROJECT_TASKS_LIST])
      queryClient.invalidateQueries([ApiQueryKeys.PROJECT_TASK])
      queryClient.invalidateQueries([ApiQueryKeys.PROJECT_TIMELINE])
      queryClient.invalidateQueries([ApiQueryKeys.TASKS_FETCHER])
      queryClient.invalidateQueries([ApiQueryKeys.TEMPLATE_TASKS_FETCHER])
      tableActions.reload([TableKey.TASKS_LIST])

      onClose()
    } catch (e) {
      showToast({
        type: 'error',
        message: t('common.generic_error'),
      })
      console.error(e)
    }
  })

  const hasAnyLocation = useMemo(() => {
    return !!task?.location && Object.values(task.location).some(value => !!value)
  }, [task?.location])

  // @TODO: Wrike can send empty tag, as empty description
  const isDescriptionHTML = task?.description === '<br />' ? false : isHTML(task?.description)

  return (
    <FormProvider {...form}>
      <SideModal
        open={isOpen}
        formConfig={{ onSubmit }}
        onWppSideModalClose={onClose}
        onWppSideModalCloseComplete={onCloseComplete}
        id={id}
        size="m"
        data-testid="task-view-modal"
      >
        {isLoading ? (
          <SideModalContentSkeleton />
        ) : (
          <>
            <WppTypography slot="header" type="2xl-heading">
              {t('project.tasks.details_modal.title')}
            </WppTypography>
            <Flex slot="body" direction="column" gap={24} className={styles.tasksContainer}>
              <>
                {isError ? (
                  <ErrorState error={error} />
                ) : (
                  task &&
                  canEdit && (
                    <>
                      <Flex direction="column" gap={24}>
                        <FormInput
                          name="name"
                          data-testid="task-name-input"
                          labelConfig={{ text: t('project.tasks.edit_modal.task_name_label') }}
                          placeholder={t('project.tasks.edit_modal.task_name_placeholder')!}
                          required
                        />

                        <SelectOsGroupForm initialValue={task?.group} />
                      </Flex>

                      <Flex direction="column" gap={24}>
                        {isDescriptionHTML ? (
                          <>
                            <WppLabel
                              config={{
                                icon: 'wpp-icon-info',
                                text: t('project.tasks.edit_modal.task_description_label'),
                                description: t('project.tasks.edit_modal.task_description_info'),
                              }}
                              typography="s-strong"
                            />
                            <div
                              className={styles.htmlDescription}
                              dangerouslySetInnerHTML={{
                                __html: parseHTMLString(task?.description).html || task?.description,
                              }}
                            />
                          </>
                        ) : (
                          <FormTextareaInput
                            name="description"
                            labelConfig={{ text: t('project.tasks.edit_modal.task_description_label') }}
                            placeholder={t('project.tasks.edit_modal.task_description_placeholder')}
                            warningThreshold={980}
                            charactersLimit={1000}
                            data-testid="modal-task-description"
                          />
                        )}

                        <WppDivider />
                        <Flex gap={24} direction="column">
                          <Flex gap={20} align="center">
                            <Flex inline direction="column" className={styles.icon} justify="center" align="center">
                              {taskTargetTypeMap[task.targetType].icon}
                            </Flex>
                            <Flex direction="column">
                              <WppTypography type="xs-midi" className={styles.greyColor800}>
                                {t('project.tasks.details_modal.task_type_label')}
                              </WppTypography>
                              <WppTypography type="s-body">{t(taskTargetTypeMap[task.targetType].name)}</WppTypography>
                            </Flex>
                          </Flex>
                          {hasAnyLocation && <TaskLocation location={task.location} />}
                        </Flex>
                      </Flex>
                    </>
                  )
                )}
              </>
            </Flex>
            <Flex slot="actions" justify="end" gap={12}>
              {canEdit ? (
                <Flex gap={12}>
                  <WppButton variant="secondary" size="m" onClick={onClose}>
                    {t('common.btn_cancel')}
                  </WppButton>
                  {!isError && (
                    <WppButton variant="primary" size="m" type="submit" loading={isSubmitting}>
                      {t('common.btn_save')}
                    </WppButton>
                  )}
                </Flex>
              ) : (
                <WppButton variant="primary" size="m" onClick={onClose}>
                  {t('common.btn_done')}
                </WppButton>
              )}
            </Flex>
          </>
        )}
      </SideModal>
    </FormProvider>
  )
}
