import { WppListItem, WppSelect } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import { useOs } from '@wpp-open/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useFetchTenantGroupsApi } from 'api/tenant/queries/useFetchTenantGroupsApi'
import { useToast } from 'hooks/useToast'
import { TenantGroup } from 'types/tenants/tenant'

interface Props {
  'data-testid'?: string
  onChange: (value: TenantGroup | undefined) => void
  value?: MayBeNull<TenantGroup>
  className?: string
}

export const SelectOsGroup = ({ 'data-testid': dataTestId, onChange, value, className }: Props) => {
  const { t } = useTranslation()
  const { showToast } = useToast()
  const {
    osContext: { tenant },
  } = useOs()
  // use custom label to display label w/o loaded option
  const [selectedLabel, setSelectedLabel] = useState<string>(value?.name || '')

  const [search, setSearch] = useState('')
  const {
    data: groups,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isError,
  } = useFetchTenantGroupsApi({
    params: { tenantId: tenant.id, search: search.trim().length >= 2 ? search.trim() : undefined },
  })

  useEffect(() => {
    if (!isError) return

    showToast({
      type: 'error',
      message: t('project.phase.settings_modal.group_load_failed'),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError])

  return (
    <WppSelect
      value={value}
      displayValue={selectedLabel}
      required
      className={className}
      size="s"
      data-testid={dataTestId}
      infinite
      loading={isLoading}
      infiniteLastPage={!hasNextPage}
      loadMore={fetchNextPage as () => Promise<any>}
      placeholder={t('project.phase.settings_modal.group_placeholder')}
      withSearch
      onWppChange={e => {
        onChange(e.detail.value)
      }}
      onWppSearchValueChange={(e: CustomEvent) => setSearch(e.detail)}
    >
      {groups.map(group => (
        <WppListItem
          key={group.id}
          value={group}
          onWppChangeListItem={e => {
            // `onWppChange` handler in `WppSelect` will not handle click on the selected item
            const selectedValue = e.detail.value as TenantGroup
            const isDeselecting = value?.id === selectedValue.id
            if (isDeselecting) {
              onChange(undefined)
              setSelectedLabel('')
              return
            }

            setSelectedLabel(group.name)
          }}
        >
          <span slot="label">{group.name}</span>
        </WppListItem>
      ))}
    </WppSelect>
  )
}
